import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import gsap from 'gsap'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import './test2.css';
import App from './test';

function Test2() {
  // useRef hook to access the canvas DOM element
  const scene = new THREE.Scene()

  //if you see this, that means you GOT THIS!!
  //variables
  let verytop = document.getElementById("top")
  let midr = document.getElementById("midr")
  let midl = document.getElementById("midl")
  let bottom = document.getElementById("bottom")


  let ongoingup = false
  let ongoingdown = false
  let ongoingleft = false
  let ongoingright = false

  //creating the plane
  const gltfLoader = new GLTFLoader();
  gltfLoader.load('../../public/assets/figuredoutexport/mayybetryagain.gltf', (gltfScene) => {
    scene.add(gltfScene.scene)
  })

  //const nameplane_geometry = new THREE.PlaneGeometry(1, 1)
  //const nameplane_material = new THREE.MeshStandardMaterial( {
  //  color: "#ffffff",
  //  roughness: 0.5,
  //  
  //})

  //const mesh = new THREE.Mesh(nameplane_geometry, nameplane_material)
  //scene.add(mesh)

  //light
  const light = new THREE.PointLight(0xffffff, 200, 100)
  light.position.set(0, 40, 0)
  light.intensity= 500
  scene.add(light)



  const lightr = new THREE.PointLight(0x0b00c1, 200, 100)
  lightr.position.set(0, 10, 10)
  lightr.intensity= 30
  scene.add(lightr)

  const lightl = new THREE.PointLight(0xb60002, 200, 100)
  lightl.position.set(10, 10, -10)
  lightl.intensity= 30
  scene.add(lightl)

  const lightu = new THREE.PointLight(0xffffff, 200, 100)
  lightu.position.set(10, 10, 0)
  lightu.intensity= 30
  scene.add(lightu)

  const lightd = new THREE.PointLight(0xe8ef40, 200, 100)
  lightd.position.set(-10, 10, 0)
  lightd.intensity= 30
  lightl.color.setHex()
  scene.add(lightd)



  //Sizes
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  //camera
  const camera = new THREE.PerspectiveCamera(45, sizes.width/sizes.height, 0.1, 100)
  camera.position.y = 10
  scene.add(camera)





  //Renderer
  const canvas = document.querySelector('.webgl');
  const renderer = new THREE.WebGLRenderer({ canvas })
  renderer.setSize(sizes.width, sizes.height)
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(2)
  renderer.render(scene, camera)



  //controls
  const controls = new OrbitControls(camera, canvas)
  controls.enableDamping = true
  controls.enablePan = true
  controls.enableZoom = false








  //resize
  window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
  });



  //loop
  const loop = () => {
    renderer.render(scene, camera)
    window.requestAnimationFrame(loop);
    controls.update()
  };
  loop()






  verytop.addEventListener("mouseenter", function(){
    move_up()
    console.log("up")
  });

  midr.addEventListener("mouseenter", function() {
    move_right()
    console.log("right")
  });
  midl.addEventListener("mouseenter", function() {
    move_left()
    console.log("left")
  });
  bottom.addEventListener("mouseenter", function() {
    move_down()
    console.log("down")
  });


  //const p0 = new THREE.Vector3(0, 0, 10)
  ////up
  //const p1 = new THREE.Vector3(0, 8, 10)
  ////right
  //const p2 = new THREE.Vector3(8, 0, 10)
  ////down
  //const p3 = new THREE.Vector3(0, -8, 10)
  ////left
  //const p4 = new THREE.Vector3(-8, 0, 10)

  //Camera rotation help, https://dustinpfister.github.io/2022/04/08/threejs-object3d-rotation/

  const p0 = new THREE.Vector3(0, 10, 0)
  //up
  const p1 = new THREE.Vector3(0, 10, 8)
  //right
  const p2 = new THREE.Vector3(8, 10, 0)
  //down
  const p3 = new THREE.Vector3(0, 10, -8)
  //left
  const p4 = new THREE.Vector3(-8, 10, 0)


  camera.position.set(2, 4, 8);

  let timeoutUp;
  let timeoutRight;
  let timeoutDown;
  let timeoutLeft;

  function move_up() {
    clearTimeout(timeoutRight);
    clearTimeout(timeoutDown);
    clearTimeout(timeoutLeft);

    let i = 1;

    function iterate_up() {
      if (i < 100) {
        const fix = i / 100;
        const elaboration = -(Math.exp(-4 * fix)) + 0.99;
        camera.position.lerpVectors(p0, p1, elaboration);
        camera.lookAt(0, 0, 0);
        renderer.render(scene, camera);
        i++;

        if (i >= 100) {
          ongoingup = false;
        } else {
          ongoingup = true;
        }

        timeoutUp = setTimeout(iterate_up, 10);
      }
    }

    iterate_up();
  }

  function move_right() {
    clearTimeout(timeoutUp);
    clearTimeout(timeoutDown);
    clearTimeout(timeoutLeft);

    let i = 1;

    function iterate_right() {
      if (i < 100) {
        const fix = i / 100;
        const elaboration = -(Math.exp(-4 * fix)) + 0.99;
        camera.position.lerpVectors(p0, p2, elaboration);
        camera.lookAt(0, 0, 0);
        renderer.render(scene, camera);
        i++;

        if (i >= 100) {
          ongoingright = false;
        } else {
          ongoingright = true;
        }

        timeoutRight = setTimeout(iterate_right, 10);
      }
    }

    iterate_right();
  }

  function move_down() {
    clearTimeout(timeoutRight);
    clearTimeout(timeoutUp);
    clearTimeout(timeoutLeft);

    let i = 1;

    function iterate_down() {
      if (i < 100) {
        const fix = i / 100;
        const elaboration = -(Math.exp(-4 * fix)) + 0.99;
        camera.position.lerpVectors(p0, p3, elaboration);
        camera.lookAt(0, 0, 0);
        renderer.render(scene, camera);
        i++;

        if (i >= 100) {
          ongoingdown = false;
        } else {
          ongoingdown = true;
        }

        timeoutDown = setTimeout(iterate_down, 10);
      }
    }

    iterate_down();
  }

  function move_left() {
    clearTimeout(timeoutRight);
    clearTimeout(timeoutDown);
    clearTimeout(timeoutUp);

    let i = 1;

    function iterate_left() {
      if (i < 100) {
        const fix = i / 100;
        const elaboration = -(Math.exp(-4 * fix)) + 0.99;
        camera.position.lerpVectors(p0, p4, elaboration);
        camera.lookAt(0, 0, 0);
        renderer.render(scene, camera);
        i++;
        if (i >= 100) {
          ongoingleft = false;
        } else {
          ongoingleft = true;
        }
        timeoutLeft = setTimeout(iterate_left, 10);
      }
    }

    iterate_left();
  }

  return (
    <div>
      {/* HTML elements */}
      <div className="top" id="top">Top</div>
      <div className="middle">
        <div className="midL" id="midl">MidLeft</div>
        <div className="midR" id="midr">MidRight</div>
      </div>
      <div className="bottom" id="bottom">bottom</div>
      {/* Three.js canvas */}
      <canvas ref={canvasRef} className="webgl"></canvas>
    </div>
  );
};

export default Test2;