import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import NotFound from './views/not-found'
import Home from './views/home'
import test from './views/test'
import Test2 from './views/test2'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Test2} exact path="/test2/" />
        <Route component={test} exact path="/test/" />
        <Route component={Home} exact path="/" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))